$boleto-simples-default-blue: #3399CC;
$white: #fff;
$black: #000;
$dark-alpha-black: rgba(0,0,0,.7);
$light-alpha-black: rgba(0,0,0,.05);
$gray: #ECF0F1;
$medium-gray: #777;
$dark_gray: #E5E8E9;
$dark-gray: $dark_gray;
$bluewood: #2C3E50;
$blue: #008de4;
$dark_blue: #2d87c3;
$dark-blue: $dark_blue;
$darkest-blue: #424766;
$main_green: #729e37;
$main-green: $main_green;
$orange: #ff9000;
$ocean: #2BD9B5;
$bright-orange:#FF5F26;
$red: #f00;
